/* DIRECTION AWARE
================== */
.da-thumbs {
        &, a { display: block }

        .image-caption {    
                position    : absolute;
                background  : @primary;
                background  : fade(@primary, 80%);
                width       : 100%;
                height      : 100%;

                h5,
                span,
                small {
                        color               : @light-text;
                        display             : block;
                        font-weight         : normal; 
                        padding             : 5px 0;
                        margin              : 25px 15px 0;
                        text-align          : center;
                        -webkit-box-shadow  : 0 1px 0 rgba(255,255,255,0.4), 0 -10px 0 rgba(255,255,255,0.4);
                           -moz-box-shadow  : 0 1px 0 rgba(255,255,255,0.4), 0 -10px 0 rgba(255,255,255,0.4);
                                box-shadow  : 0 1px 0 rgba(255,255,255,0.4), 0 -10px 0 rgba(255,255,255,0.4);
                        .text-shadow();
                }

                small { margin: 15px 5px 0; }
        }
}