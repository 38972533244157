/* PAGINATION AND TAGS 
====================== */
.tags,
.pagination { 
    .ul-no-style(); 

    li {
        margin          : 0 2px 2px 0;
        .transition();

        a,
        > span { 
            background      : @lighter-bg;  
            color           : @darker-text;
            display         : block; 
            font-size       : 13px;
            line-height     : 1; 
            padding         : 8px 10px;
        }
        
        a:hover {
            background-color: @primary;
            color           : @light-text;
        }
    }
}

/* TAGS
=======*/
#footer-main .tags {  
    margin-bottom: 15px; 

    li {
        margin: 0 1px 1px 0;

        a { 
            background: @dark-border;
            .transition(all, 2s, ease-in);

            &:hover { 
                background-color: @primary;
                color           : @light-text;
                .transition(all, .4s); 
            }
        }
    }        
}