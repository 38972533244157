/* -------------------------------------------------------------------------- *
 * Page links (pagination for post that has multiple page)
 * -------------------------------------------------------------------------- */
.page-links {
        margin-bottom: 1em;

        a, > span,
        li > span {
                background      : @lighter-bg; 
                color           : @darker-text;
                display         : inline-block; 
                line-height     : 1; 
                padding         : 8px 10px;

                &:hover { 
                        background-color: @primary;
                        color           : @light-text;
                }
        }

        .dots, .dots:hover {
                background      : @lighter-bg;  
                color           : @darker-text;
        }

        > span,
        li > span  {
                &, &:hover {
                        background  : @semi-dark-bg; 
                        color       : @light-text;
                }                 
        }
}