/* ARTICLES TINY 
================ */
.article-tiny {
    .image {
        float   : left;
        width   : 95px;
    }
    
    .csstransforms & .image-holder {
        padding-bottom: 75/95 * 100%;
    }
}