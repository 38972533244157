.nivo-lightbox-close {
    top: 15px;
    right: 15px;
}

.nivo-lightbox-theme-default {    
    .nivo-lightbox-prev {
        /*rtl:raw:
        background-image: url(../img/nivo-lightbox-default-theme/next.png);*/
    }
    
    .nivo-lightbox-next {
        /*rtl:raw:
        background-image: url(../img/nivo-lightbox-default-theme/prev.png);*/
    }
}