/* PAGINATION 
============= */
.pagination {
    display : table;
    margin  : 0 -2px 45px -2px;

    li {
        display     : table-cell;
        float       : none; 
        margin-right: 5px;
        padding     : 0 2px;
        
        a,
        > span {
            white-space: nowrap;
        }

        &.active { 
            a,
            > span {
                background  : @semi-dark-bg; 
                color       : @light-text; 
                cursor      : default; 
            }
        }

        &.divider { 
            background  : url(../img/line.jpg) repeat-x center;
            width       : 50%;
        }
    }
}