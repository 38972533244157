/* SOCIAL MEDIA ICONS 
===================== */
.sc-sm, 
.sc-md {
    background-image        : url("../img/social-media-icons.png");
    color                   : white;
    font-family             : FontAwesome;
    font-style              : normal; 
    float                   : left;
    text-align              : center;     
    -webkit-font-smoothing  : antialiased;
    -moz-osx-font-smoothing : grayscale;
    .transition(background-color);
    .text-shadow();

    &:hover:not(.sc-dark) { background-color:  @primary; }

    &.sc-mail { 
        background: #2EA2CC;     
        &:before { content: '\f0e0' }
    }
    &.sc-codepen { 
        background: #252527;     
        &:before { content: "\f1cb" }
    }
    &.sc-foursquare {
        background: #009fe0;
        &:before { content: "\f180" }
    }
    &.sc-reddit {
        background: #6CC0FE;
        &:before { content: "\f1a1" }
    }
    &.sc-renren {
        background: #105BA3;
        &:before { content: "\f18b" }
    }
    &.sc-stack-exchange {
        background: #12457C;
        &:before { content: "\f18d" }
    }
    &.sc-stack-overflow {
        background: #f90;
        &:before { content: "\f16c" }
    }
    &.sc-steam {
        background: #3b3938;
        &:before { content: "\f1b6" }
    }
    &.sc-tencent-weibo {
        background: #46C1E3;
        &:before { content: "\f1d5" }
    }
    &.sc-vine {
        background: #00bf8f;
        &:before { content: "\f1ca" }
    }
    &.sc-vk {
        background: #4E729A;
        &:before { content: "\f189" }
    }
    &.sc-weibo {
        background: #e64141;
        &:before { content: "\f18a" }
    }
}

/* Icons Colors
--------------- */
.sc-facebook      { background-color: #5d82d1; } 
.sc-pinterest     { background-color: #e13138; } 
.sc-twitter       { background-color: #40bff5; } 
.sc-googleplus    { background-color: #eb5e4c; } 
.sc-rss           { background-color: #faa33d; } 
.sc-vimeo         { background-color: #35c6ea; } 
.sc-evernote      { background-color: #9acf4f; } 
.sc-dribbble      { background-color: #f7659c; } 
.sc-tumblr        { background-color: #426d9b; } 
.sc-behance       { background-color: #1879fd; } 
.sc-stumbleupon   { background-color: #ff5c30; } 
.sc-dropbox       { background-color: #17a3eb; } 
.sc-soundcloud    { background-color: #ff7e30; } 
.sc-picasa        { background-color: #9eb5b6; } 
.sc-lastfm        { background-color: #f34320; } 
.sc-forrst        { background-color: #45ad76; } 
.sc-flickr        { background-color: #ff48a3; } 
.sc-deviantart    { background-color: #6a8a7b; } 
.sc-linkedin      { background-color: #238cc8; } 
.sc-blogger       { background-color: #ff9233; } 
.sc-instagram     { background-color: #548bb6; } 
.sc-yahoo         { background-color: #ab47ac; } 
.sc-youtube       { background-color: #ef4e41; } 
.sc-grooveshark   { background-color: #ffb21d; } 
.sc-digg          { background-color: #75788d; } 
.sc-skype         { background-color: #13c1f3; } 
.sc-sharethis     { background-color: #25a774; } 
.sc-wordpress     { background-color: #2592c3; } 
.sc-kickstarter   { background-color: #8cd049; } 
.sc-bebo          { background-color: #ee3849; } 
.sc-zerply        { background-color: #9dbc7a; } 
.sc-amazon        { background-color: #ff8e2e; } 
.sc-myspace       { background-color: #008dde; } 
.sc-wikipedia     { background-color: #b3b5b8; } 
.sc-technorati    { background-color: #71d14b; } 
.sc-addthis       { background-color: #ff7850; } 
.sc-delicious     { background-color: #377bda; } 
.sc-xing          { background-color: #1a8e8c; } 
.sc-quora         { background-color: #ea3d23; } 
.sc-github        { background-color: #3f91cb; }
.sc-feedly        { background-color: #2bb24c; }

/* Small Icons
-------------- */
.sc-sm {
    background-size : 1230px;
    font-size       : 20px; 
    height          : 30px;
    line-height     : 30px; 
    width           : 30px;
    .box-shadow-inset(0, 15px, 0, 0, rgba(255, 255, 255, .2));
    
    &.sc-facebook      { /*rtl:begin:ignore*/ background-position: 0 0; }
    &.sc-pinterest     { background-position: -30px 0; } 
    &.sc-twitter       { background-position: -60px 0; }
    &.sc-googleplus    { background-position: -90px 0; }
    &.sc-rss           { background-position: -120px 0; }
    &.sc-vimeo         { background-position: -150px 0; }
    &.sc-evernote      { background-position: -180px 0; }
    &.sc-dribbble      { background-position: -210px 0; }
    &.sc-tumblr        { background-position: -240px 0; }
    &.sc-behance       { background-position: -270px 0; }
    &.sc-stumbleupon   { background-position: -300px 0; }
    &.sc-dropbox       { background-position: -330px 0; }
    &.sc-soundcloud    { background-position: -360px 0; }
    &.sc-picasa        { background-position: -390px 0; }
    &.sc-lastfm        { background-position: -420px 0; }
    &.sc-forrst        { background-position: -450px 0; }
    &.sc-flickr        { background-position: -480px 0; }
    &.sc-deviantart    { background-position: -510px 0; }
    &.sc-linkedin      { background-position: -540px 0; }
    &.sc-blogger       { background-position: -570px 0; }
    &.sc-instagram     { background-position: -600px 0; }
    &.sc-yahoo         { background-position: -630px 0; }
    &.sc-youtube       { background-position: -660px 0; }
    &.sc-grooveshark   { background-position: -690px 0; }
    &.sc-digg          { background-position: -720px 0; }
    &.sc-skype         { background-position: -750px 0; }
    &.sc-sharethis     { background-position: -780px 0; }
    &.sc-wordpress     { background-position: -810px 0; }
    &.sc-kickstarter   { background-position: -840px 0; }
    &.sc-bebo          { background-position: -870px 0; }
    &.sc-zerply        { background-position: -900px 0; }
    &.sc-amazon        { background-position: -930px 0; }
    &.sc-myspace       { background-position: -960px 0; }
    &.sc-wikipedia     { background-position: -990px 0; }
    &.sc-technorati    { background-position: -1020px 0; }
    &.sc-addthis       { background-position: -1050px 0; }
    &.sc-delicious     { background-position: -1080px 0; }
    &.sc-xing          { background-position: -1110px 0; }
    &.sc-quora         { background-position: -1140px 0; }
    &.sc-github        { background-position: -1170px 0; }
    &.sc-feedly        { background-position: -1200px 0; /*rtl:end:ignore*/ }
}

/* Medium Icons
--------------- */
.sc-md {
    font-size       : 30px; 
    height          : 45px;
    line-height     : 45px; 
    width           : 45px;
    .box-shadow-inset(0, 22.5px, 0, 0, rgba(255, 255, 255, .2));
    
    &.sc-facebook    { /*rtl:begin:ignore*/ background-position: 0 0; }
    &.sc-pinterest   { background-position: -45px 0; }
    &.sc-twitter     { background-position: -90px 0; }
    &.sc-googleplus  { background-position: -135px 0; }
    &.sc-rss         { background-position: -180px 0; }
    &.sc-vimeo       { background-position: -225px 0; }
    &.sc-evernote    { background-position: -270px 0; }
    &.sc-dribbble    { background-position: -315px 0; }
    &.sc-tumblr      { background-position: -360px 0; }
    &.sc-behance     { background-position: -405px 0; }
    &.sc-stumbleupon { background-position: -450px 0; }
    &.sc-dropbox     { background-position: -495px 0; }
    &.sc-soundcloud  { background-position: -540px 0; }
    &.sc-picasa      { background-position: -585px 0; }
    &.sc-lastfm      { background-position: -630px 0; }
    &.sc-forrst      { background-position: -675px 0; }
    &.sc-flickr      { background-position: -720px 0; }
    &.sc-deviantart  { background-position: -765px 0; }
    &.sc-linkedin    { background-position: -810px 0; }
    &.sc-blogger     { background-position: -855px 0; }
    &.sc-instagram   { background-position: -900px 0; }
    &.sc-yahoo       { background-position: -945px 0; }
    &.sc-youtube     { background-position: -990px 0; }
    &.sc-grooveshark { background-position: -1035px 0; }
    &.sc-digg        { background-position: -1080px 0; }
    &.sc-skype       { background-position: -1125px 0; }
    &.sc-sharethis   { background-position: -1170px 0; }
    &.sc-wordpress   { background-position: -1215px 0; }
    &.sc-kickstarter { background-position: -1260px 0; }
    &.sc-bebo        { background-position: -1305px 0; }
    &.sc-zerply      { background-position: -1350px 0; }
    &.sc-amazon      { background-position: -1395px 0; }
    &.sc-myspace     { background-position: -1440px 0; }
    &.sc-wikipedia   { background-position: -1485px 0; }
    &.sc-technorati  { background-position: -1530px 0; }
    &.sc-addthis     { background-position: -1575px 0; }
    &.sc-delicious   { background-position: -1620px 0; }
    &.sc-xing        { background-position: -1665px 0; }
    &.sc-quora       { background-position: -1710px 0; }
    &.sc-github      { background-position: -1755px 0; }
    &.sc-feedly      { background-position: -1800px 0; /*rtl:end:ignore*/ }
}    

/* Dark Icons 
----------------------------------------------------------------------------- */
.sc-dark:not(:hover) {
    background-color: transparent;
    opacity         : .6;
    .box-shadow-none();
}

/* Icons on Widget
------------------ */
ul.social {
    .ul-no-style();
    margin: 0 -9.04px -18px -9px; /*IE needs 0.04px extra margin*/

    li {
        margin: 0 9px 18px 9px; 
        span { float: left; }
    }
}

#footer-main ul.social {
    margin: 0 -10px -9.5px 0;
    
    li {
        margin: 0 9.5px 9.5px 0;
        
        .sc-dark:not(:hover) {
            background-color  : @semi-dark-bg;
        }
    }
}