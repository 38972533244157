/* POST FORMAT ICONS 
==================== */
.dashicons-format-link:before { content: "\f103"; }

.icons {
        .dashicons { line-height: 65px }
        
        .dashicons-format-standard  { background-color: @format-standard; }
        .dashicons-format-status    { background-color: @format-status; }
        .dashicons-format-quote     { background-color: @format-quote; }
        .dashicons-format-gallery   { background-color: @format-gallery; }
        .dashicons-format-image     { background-color: @format-image; }
        .dashicons-format-video     { background-color: @format-video; }
        .dashicons-format-audio     { background-color: @format-audio; }
        .dashicons-format-link      { background-color: @format-link; }
        .dashicons-format-aside     { background-color: @format-aside; }
        .dashicons-format-chat      { background-color: @format-chat; }
}