/* ARTICLES SMALL 
================= */
.article-small,
.article-tiny {
        .image {     
                margin  : 0 15px 15px 0;
                position: relative;
                width   : 100%;
        }
        
        h5 { margin-bottom: 10px }
}

.article-small {
    .image { margin-bottom: 10px; }
    
    .csstransforms & .image-holder {
        padding-bottom: 90/165 * 100%;
    }
}