/* FOOTER 
========= */
#footer-main {
        background-color: @dark-bg;
        color           : lighten( @light-bg, 4% );
        position        : relative;
        z-index         : 1;

        a {        
                &:hover {
                        img {
                                filter          : url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
                                -webkit-filter  : grayscale(0%);
                        }
                }
                img {  
                        filter              : url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
                        filter              : gray; /* IE6-9 */
                        -webkit-filter      : grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
                }
        }
        
        a { 
                color: darken(@dark-text, 10%);
                &:hover { color: @primary }
        }
        
        hr {
            border-color: transparent;
            border-color: rgba(255,255,255,.05);
        }
        
        .container { padding-top: 30px }

        .post-meta,
        .comment-meta { 
                a:not(:hover) { color: darken( @light-bg, 10% ) }
        }

        .image-light,
        .link { display: none }

        .row section {
                margin-bottom: 30px;

                &.col-md-3:nth-child(4n+1) { clear: both }
                &.col-md-2:nth-child(6n+1) { clear: both }
        }
        
        // frame
        .frame {
            background-color:   @dark-border;
            border-color:       @dark-border;
        }
        
        // images
        .image {
            .image-holder {
                background: #000;
                
                img {
                    opacity:    .8;
                    .transition( opacity 0.4s ease-out );
                }
            }

            &:hover .image-holder {
                transform: none;
                
                img {
                    opacity: 1;
                }
            }
        }      
        
        @media(max-width: (@screen-md-min - 1)) {
                .row section { 
                        &.col-sm-6:nth-child(odd)   { clear: both; }                
                        &.col-sm-4:nth-child(3n+1)  { clear: both; } 
                }        
        }
}

#footer-bottom {
        color           : lighten( @light-bg, 4% );
        background-color: @darker-bg;

        a {
                background      : none;
                color           : inherit;

                &:hover { color: @primary }
        }
        p {
                float       : left;
                margin      : 0;
        }
        ul {
                float   : right;
                margin  : 0;
                .ul-no-style();

                li { margin-left: 20px; }
        }

        .container { padding: 15px; }
        
        @media(max-width: 767px) {        
                .container {            
                        p { margin-bottom: 15px }

                        p,
                        ul {
                                float       : none !important;
                                display     : block;

                                li { margin: 0 20px 10px 0; }
                        }
                }
        }
}

#footer-main,
#footer-bottom {
        box-shadow      : inset 0 1px 1px 0 rgba( 0, 0, 0, 0.16 ); 
}