/* NAVBAR MAIN 
============== */
#main-nav {
        z-index         : 2; 
        box-shadow      : inset 0 30px 0 0 #fff, 0 1px 1px 0 rgba( 0, 0, 0, 0.16 );

        .navbar-header {
                .navbar-brand { 
                        position        : relative;

                        img { 
                                max-height              : 100%;
                                height                  : auto;
                                width                   : auto;
                                position                : relative;
                                top                     : 50%;
                                -webkit-transform       : translateY(-50%);
                                   -moz-transform       : translateY(-50%);
                                    -ms-transform       : translateY(-50%);
                                     -o-transform       : translateY(-50%);
                                        transform       : translateY(-50%);
                        } 
                }

                .navbar-toggle {
                        &:hover { 
                                border-color    : transparent;
                                background-color: @primary;

                                .icon-bar { background-color: @light-text; }
                        }
                }
                
                @media(max-width: ( @screen-sm-max ) ) {
                        .navbar-brand {
                                max-width: 80%;
                                
                                img { 
                                        max-width: 100%; 
                                }
                        }
                }
        }

        .navbar-nav {        
                > li {           
                        @media(min-width: @screen-md-min) {
                                > a {
                                        padding         : 20px 15px;
                                }          
                                
                                &.open > a,
                                &.active > a,
                                & > a:hover {                                                       
                                        .box-shadow-inset(0, 30px, 0, 0, rgba(255, 255, 255, .16));

                                        .caret { border-top-color: @light-text; }                       
                                }     
                        }
                }     
        }    
}