/* NAVBAR TOP 
============= */
#top-nav {
        min-height      : 30px;    
        box-shadow      : inset 0 15px 0 0 rgba(255, 255, 255, .04);

        .nav > li > a {
                border-color    : @darker-border;
                line-height     : 14px;
                padding         : 8px 15px;  
        } 

        .navbar-form {
                padding     : 0;

                button, input { float: left }

                input[type="search"], 
                input[type="text"] { 
                        border          : 0;
                        margin          : 0;
                        height          : 30px;
                        background-color: @darker-bg;
                        color           : @dark-text;
                        
                        @media(min-width: @screen-md-min) { 
                                .box-shadow-inset(0, 1px, 4px, 0, rgba(0, 0, 0, .1));
                        }
                }

                button,
                input[type="submit"] {
                        color       : @dark-text;
                        border      : 0;
                        background  : none;
                        height      : 30px;
                        &:hover { 
                                color                   : @light-text;
                                background-color        : @primary;
                                .box-shadow-inset(0, 15px, 0, 0, rgba(255,255,255,0.2));
                        }
                }
        }

        .navbar-right {
                &:last-child {
                        margin-right    : 0;
                }
                
                li a { 
                        padding : 0; 
                }
        } 

        .navbar-toggle {
                border          : 0; 
                margin-top      : 0; 
                margin-bottom   : 0; 

                &:hover     { 
                        .box-shadow-inset(0, 15px, 0, 0, rgba(255, 255, 255, .2)); 
                }   
        }
        
        @media(min-width: @screen-md-min) {
                .navbar-nav > li  {       
                        > a {
                                height  : 30px;
                        }
                        
                        > a:hover,
                        &.active > a {
                                .box-shadow-inset(0, 15px, 0, 0, rgba(255, 255, 255, .2));
                        }
                }         
                
                .navbar-form {
                        margin          : 0;
                        padding-right   : 1px;
                        
                        input,
                        button {
                                border-right    : 1px solid @darker-border;
                                border-left     : 1px solid @darker-border;
                                margin-right    : -1px; 
                        }
                }
        }

        @media( max-width: @screen-sm-max ) {
                .navbar-form    {                         
                        border          : none;
                        display         : inline-block;
                        margin-top      : 0;
                        
                        input[type="search"], 
                        input[type="text"] {
                                width: auto;
                        }
                        
                        .box-shadow-none();
                        .clearfix();
                }   
        }
}