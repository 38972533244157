/* FLAT STYLE 
============= */
.flat-style {
        * { text-shadow: none; }
        
        button,
        input[type="button"], 
        input[type="reset"], 
        input[type="submit"],
        .btn,
        
        #top-nav,
        #top-nav .navbar-toggle,
        #top-nav .navbar-form button, 
        #top-nav .navbar-form input[type="submit"],
        
        #main-nav,
        #main-nav .navbar-toggle,
        
        .sc-sm, 
        .sc-md,
        
        .widget header h4,
        .widget .nav-tabs li a,
        .widget header .control,
        
        .article-large .icons a i,
        .dashicons,
        .dropdown-menu,
        
        body > header,
        body > footer,
        body > #content,
        #footer-main,
        #footer-bottom {
                box-shadow      : none;
        }
        
        #main-nav {
                background-color: white;
        }
        
        #top-nav,
        #main-nav {
                button,
                input[type="submit"],
                .nav > li > a  {
                        text-shadow: none;
                        
                        &, &:hover {
                                @media(min-width: @screen-md-min) {
                                        .box-shadow-none();
                                }
                        }
                }
        }

        .breaking-news .header .triangle:before                         { background: none }   
        
        &#blog-version-2 #main-content article .frame,
        .articles-v1 article .frame                                     { margin-bottom: 15px }  
        
        &#blog-version-2  #main-content article .featured-media .frame,
        .featured-media .frame                                          { margin-bottom: 0  }
        
        .shadow                                                         { display: none }  
        
        .article-large .icons a,
        .post-comments .comment-container .avatar                       { .box-shadow(0, 0); }
}
