/* Page title */
#title {
    background-color: @lighter-bg; 
    background-size: cover;    
    background-position: center; 
    height: 150px;
    position: relative;
    overflow: hidden;
    width: 100%;

    .container {
        height  : 100%;
        position: relative;

        .title-container {
            bottom      : 20px; 
            color       : @light-text;
            margin-right: 15px;
            position    : absolute;        

            .primary,
            .secondary { float: left; }

            p { margin: 2px 0 0 0; clear: both; }

            .primary {
                font-size   : 20px;
                line-height : 1; 
                background  : @primary;
                background  : fade(@primary, 80%); 
                padding     : 10px;
                margin      : 0;
            }

            .secondary {
                background      : rgba(0, 0, 0, .8); 
                font-family     : @body-font; 
                font-size       : 15px;
                line-height     : 1;
                padding         : 12.5px 10px;

                a { 
                    color: @light-text;

                    &:hover { color: @primary }
                }
            }
        }
    }     
}
