.rateit {
    display: -moz-inline-box;
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.rateit .rateit-range
{
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
    background: url(../img/rateit/star.png);
    height: 16px;
    outline: none;
    margin-bottom: -3px;
}

.rateit .rateit-range * {
    display:block;
}

/* for IE 6 */
* html .rateit, * html .rateit .rateit-range
{
    display: inline;
}

/* for IE 7 */
* + html .rateit, * + html .rateit .rateit-range
{
    display: inline;
}

.rateit .rateit-hover, .rateit .rateit-selected
{
    position: absolute;
    left: 0px;
}

.rateit .rateit-hover-rtl, .rateit .rateit-selected-rtl
{
    left: auto;
    right: 0px;
}

.rateit .rateit-hover
{
    background: url(../img/rateit/star.png) left -32px;
}

.rateit .rateit-hover-rtl
{
    background-position: right -32px;
}

.rateit .rateit-selected
{
    background: url(../img/rateit/star.png) left -16px;
}

.rateit .rateit-selected-rtl
{
    background-position: right -16px;
}

.rateit .rateit-preset
{
    background: url(../img/rateit/star.png) left -32px;
}

.rateit .rateit-preset-rtl
{
    background: url(../img/rateit/star.png) left -32px;
}

.rateit button.rateit-reset
{
    background: url(../img/rateit/delete.gif) 0 0;
    width: 16px;
    height: 16px;
    display: -moz-inline-box;
    display: inline-block;
    float: left;
    outline: none;
    border:none;
    padding: 0;
    .box-shadow-none();
}

.rateit button.rateit-reset:hover, .rateit button.rateit-reset:focus
{
    background-position: 0 -16px;
}