/* MESSAGE FORM 
=============== */
.message-form,
.comment-respond {
        form {
                margin: 0 -10.00000000000001px 0 0;

                .input-group {
                        float   : left;
                        padding : 0 10px 10px 0;
                        width   : 33.33333333333333%;

                        @media(max-width: 768px) { width: 99.99999999999999%; }
                }

                .textarea { 
                        clear   : both;
                        padding : 0 10px 10px 0;
                        width   : 99.99999999999999%; 

                        textarea { width: 100% }
                }

                .allowed-tags,
                .form-allowed-tags {
                        color       : @dark-text; 
                        margin      : 10px 0;
                }
        }        
}