/* BUTTONS 
========== */
button,
input[type="button"], 
input[type="reset"], 
input[type="submit"] {
        &:extend( .btn all, .btn-primary all );
}

.btn-dark {
        border          : 0;
        background-color: @semi-dark-bg;
        color           : @dark-text;    
        
        &:hover { 
                background-color: @primary; 
                color           : @light-text; 
        }
}

#footer-main {
        button,
        input[type="button"], 
        input[type="reset"], 
        input[type="submit"],
        .btn {
                .btn-dark();
        }
}

/* LABELS
========= */
.label { display: inline-block }

/* INPUTS
========= */
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {        
        &:extend( .form-control all );
        
        margin-bottom: 15px;
}

textarea {
        height  : auto;
}

.input-group {
    display : table;
    width   : 100%;


    i {
        background-color: @semi-dark-bg;
        color           : @light-text;
        font-size       : 14px;
        line-height     : 1;
        padding         : 8px 10px;
        text-align      : center;
        vertical-align  : middle; 
        width           : 40px;
        .box-shadow-inset();
    }

    i, input  { display : table-cell;  }
    input     { margin  : 0 }      
}

#footer-main .input-group i {
        background-color: @semi-dark-bg;
        color           : @dark-text;
        .box-shadow-none();        
}

.input-error {
    background-color: #f2dede;
    color           : #a94442;
    
    &::-webkit-input-placeholder { /* WebKit browsers */
        color:    #a94442;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:    #a94442;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:    #a94442;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color:    #a94442;
    }
}

#footer-main {
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"],
        input[type="search"],
        input[type="tel"],
        input[type="color"] {
                border          : 0;
                background-color: @darker-bg;
                color           : @dark-text;
                .box-shadow-inset(0, 1px, 4px, 0, rgba(0, 0, 0, .1));
        }
}

/* IMAGE LIGHT 
============================================================================= */
.image-light {
    background          : -webkit-gradient(linear,left top,left bottom,color-stop(rgba(255, 255, 255, .2),0),color-stop(rgba(0, 0, 0, 0),0.6667));
    background          : -webkit-linear-gradient(rgba(255, 255, 255, .2) 0%, rgba(0, 0, 0, 0) 80%);
    background          :    -moz-linear-gradient(rgba(255, 255, 255, .2) 0%, rgba(0, 0, 0, 0) 80%);
    background          :      -o-linear-gradient(rgba(255, 255, 255, .2) 0%, rgba(0, 0, 0, 0) 80%);
    background          :         linear-gradient(rgba(255, 255, 255, .2) 0%, rgba(0, 0, 0, 0) 80%);
    display             : block;
    height              : 100%;
    position            : absolute;
    right               : -70%;
    top                 : 0;
    width               : 100%;
    -webkit-transform   : skewX(24deg);
       -moz-transform   : skewX(24deg);
        -ms-transform   : skewX(24deg);
         -o-transform   : skewX(24deg);
            transform   : skewX(24deg);
}
.no-image-light .image-light { display: none }

/* IMAGES
============================================================================= */
.article-small,
.article-medium,
.da-thumbs,
.frame  { 
        img {
            width   : 100%;
            height  : auto
        }
}

.entry-content,
.comment-container .comment,
.article-large,
.featured-media {
    > * { max-width: 100%; }
    
    img { 
            max-width   : 100%;
            height      : auto; 
    }
}

/* LAYER
============================================================================= */
.layer {
    height      : 100%;
    position    : absolute;
    top         : 0;
    width       : 100%; 
    .transition(opacity);
}
a.dark {    
    &:hover .layer { opacity: 0 }
    
    .layer {
        background  : rgba(0, 0, 0, .4); 
        opacity     : 1;
    }
}

a.light {
    &:hover .layer { opacity: 1 }
    
    .layer {
        background  : rgba(255, 255, 255, .2); 
        opacity     : 0;
    }
}

a.dark,
a.light {
    display : block;
    position: relative;
}

/* PROGRESS
============================================================================= */
.progress {
    margin-bottom: 15px;
    
    .progress-bar { background-color: @primary }
}

/* FEATURED MEDIA
============================================================================= */
.featured-media { 
    margin-bottom: 15px;
    
    > * { 
        max-width: 100%;
        margin-bottom: 0;
    }
}

/* GENERAL
============================================================================= */
body,
.container { 
        max-width       : 100% 
}

*:focus {
        outline : none;
}