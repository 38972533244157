/* Boxed style */
.boxed-style {
    body {
        max-width: 1260px;
        background-color: @lighter-bg;
        background-attachment: fixed;
        background-position: center;
        .box-shadow(0, 4px, 12px, 0, rgba(0, 0, 0, .4));

        #content {
            background-color: #fff;
        }

        @media (min-width: 828px) {
            margin: 45px;                       
        }

        @media (min-width: 1350px) { 
            margin: 45px auto; 
        }
    }
}