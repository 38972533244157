/* POST COMMENTS 
================ */
.post-comments {
        margin-bottom: 30px;
        
        &, 
        ol.children,
        ol.children > li > ol { 
                list-style  : none;
                padding     : 0;
        }

        ol.children,
        ol.children > li > ol  {
                margin-left: 90px;
        }

        .comment-container {
                display : table;
                margin  : 0 0 15px 0;
                width   : 100%;

                .avatar {
                        border          : 5px solid white;
                        border-radius   : 37.5px;
                        margin          : 0 15px 0 0!important;
                        height          : 75px; 
                        width           : 75px; 
                        .box-shadow(0, 1px, 1px, 0, rgba(0, 0, 0, .4));
                }

                .avatar, 
                .content { 
                        display         : table-cell; 
                        vertical-align  : top;
                }

                .content {
                        width   : 99%;

                        .comment-meta { 
                                float   : left; 
                                margin  : 0 10px 0 0;
                                
                                a { display: inline-block }                
                        }

                        .reply {
                                color       : @dark-text;
                                float       : right;
                                font-family : @headings-font;
                                font-size   : 13px;
                                line-height : 1;

                                &:hover { color: @primary }
                        }

                        .comment {
                                background      : @lighter-bg;
                                clear           : both;
                                display         : block;
                                padding         : 15px 15px 0;
                                position        : relative;

                                &:after {
                                        content: '';
                                        display: table;
                                }
                                
                                blockquote {
                                        background: white;
                                }

                                .triangle {
                                        border-right    : 10px solid @lighter-bg;
                                        border-top      : 7.5px solid transparent; 
                                        border-bottom   : 7.5px solid transparent; 
                                        left            : -10px;
                                        position        : absolute;
                                        top             : 10px;
                                }
                        }
                }
        }

        .children > li  > .comment-container {
                .avatar     { margin: 0 0 0 15px!important }

                .content .comment { 
                        background-color: @primary;
                        color           : @light-text;
                        
                        a {
                                color           : @light-text;
                                text-decoration : underline;
                                
                                &:hover {
                                        color: fade(@light-text, 80%);
                                }
                        }

                        blockquote {
                                background  : lighten(@primary, 8%);
                                border-color: lighten(@primary, 24%);
                                color       : lighten(@light-text, 8%);   

                                footer, cite { color: @light-text; }
                        }

                        .triangle { 
                                border-right    : none;
                                border-left     : 10px solid @primary;
                                border-top      : 7.5px solid transparent; 
                                border-bottom   : 7.5px solid transparent; 
                                left            : auto;
                                right           : -10px; 
                        }       
                }
        }

        @media(max-width: 768px) {
                .avatar { 
                        display : none!important 
                }
                
                ol.children,
                ol.children > li > ol { 
                        margin-left     : 30px 
                }
        }
}