/* BREAKING NEWS WIDGET
======================= */
.breaking-news { 
        margin-bottom: 45px;

        header {      
                position        : absolute; 
                white-space     : nowrap; 
                height          : 30px;
                overflow        : hidden;

                h4 {
                        background-color: @semi-dark-bg;
                        color           : @light-text;
                        float           : left;
                        line-height     : 30px; 
                        padding         : 0 10px; 
                }

                .triangle {
                        border-color    : @semi-dark-bg;
                        border-left     : 10px solid @semi-dark-bg;
                        border-top      : 15px solid transparent;
                        border-bottom   : 15px solid transparent;
                        float           : left;             
                }
        }

        .content {
                background-color: @lighter-bg;
                height          : 30px;
                line-height     : 30px;
                overflow        : hidden; 
                padding         : 0 10px;

                ul {
                        .ul-no-style();
                        li { 
                                margin-right: 15px; 
                                a {
                                        color: @darker-text;
                                        &:hover { color: @primary }
                                }
                        }
                }
        }    
}