/* CAROUSEL 
=========== */
.carousel {  
    overflow: hidden;

    .carousel-caption {        
        bottom      : 0;
        left        : 0;
        padding     : 20px;
        width       : 100%;
        text-align  : left; 
        text-shadow : none;
        z-index     : 1;

        div { 
            clear: both; 
        }

        h1, h2, h3, h4, h5, h6, p {
            color           : @light-text;
            float           : left; 
            margin          : 0 0 2px;
            padding         : 10px; 
        }

        h1, h2, h3, h4, h5, h6  { 
            background-color: @primary;
            background-color: fade(@primary, 80%);
            font-size       : 20px; 
            line-height     : 1; 

            @media(max-width: 767px) {
                font-size   : 15px;
            }
        }

        p   { 
            background-color: rgba(0, 0, 0, .8);
            font-size       : 15px; 
            line-height     : 20/15; 
        }
    }

    .carousel-control {
        background  : none;
        cursor      : default;
        filter      : none;
        opacity     : 1;
        width       : 30px;
        z-index     : 2;

        span {
            background-color: @primary;
            background-color: fade(@primary, 80%);
            cursor          : pointer;
            font-size       : 15px;
            left            : 0;
            line-height     : 1; 
            margin-top      : -17.5px; 
            opacity         : .8; 
            padding         : 10px;
            position        : absolute;
            text-shadow     : none; 
            top             : 50%;   
            width           : 30px;  
            .transition();

            &:hover { 
                opacity   : 1 
            }
        }

        &.left  { 
            left  : -60px; 
        }
        
        &.right { 
            right : -60px; 
        }
    }

    .carousel-indicators {         
        bottom  : -48px; 
        .transition(bottom);

        li { 
            border-color    : @primary;
            border-color    : fade(@primary, 80%);

            &.active {                
                background-color: @primary;
                background-color: fade(@primary, 80%);
            }
        };
    }

    &:hover {        
        .carousel-indicators { 
            bottom: 0 
        }

        .carousel-control { 
            &.right { right : 0 }
            &.left  { left  : 0 }
        }
    }

    .item img {
            position    : relative;
            top         : 0;
            left        : 0;
            min-width   : 100%;  
    }    
}

// Carousel Large
.carousel-large .carousel-caption { 
    padding: 40px 
}

// Carousel Medium and Small
.csstransforms {
    .carousel-medium,
    .carousel-small {
        .item-inner {
            display:        block;
            position:       relative;
            padding-bottom: 9/16 * 100%;

            img {
                position:   absolute;
                top:        50%;
                width:      100%;
                height:     auto;
                min-height: 100%;
                .translate(0; -50%);
            }
        }
    }
    
    .carousel-medium .item-inner {
        padding-bottom: 370/730 * 100%;        
    }
    
    .carousel-small .item-inner {
        padding-bottom: 185/350 * 100%;        
    }
}

// Caption animation

// Fade
.caption-fade {
    .carousel-caption {
        h1, h2, h3, h4, h5, h6, p {
            transition: opacity 1s @easeOutExpo;
            opacity: 0;
        }
    }

    .active {
        .carousel-caption {
            h1, h2, h3, h4, h5, h6, p {
                opacity: 1;
            }

            .rand-1 {
                transition-delay: .2s;
            }
        }

        &.left,
        &.right {
            .carousel-caption {
                h1, h2, h3, h4, h5, h6, p {
                    opacity: 0;
                }

                .rand-1 {
                    transition-delay: 0s;
                }
            }
        }
    }
}

// Slide
.caption-slide {
    .carousel-caption {
        h1, h2, h3, h4, h5, h6, p {
            transition: transform 1s @easeOutExpo, opacity 1s @easeOutExpo;
            transform: translateX(50%);
            opacity: 0;
        }

        .rand-1 {
            transform: translateX(-50%);
        }
    }

    .active {
        .carousel-caption {
            h1, h2, h3, h4, h5, h6, p {
                transform: translateX(0);
                opacity: 1;
            }

            p {
                transition-delay: .2s;
            }
        }

        &.left,
        &.right {
            .carousel-caption {
                h1, h2, h3, h4, h5, h6, p {
                    transform: translateX(100%);
                    opacity: 0;
                }

                .rand-1 {
                    transform: translateX(-100%);
                }

                p {
                    transition-delay: .2s, 0s;
                }
            }
        }
    }
}
        