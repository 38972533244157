/* TYPOGRAPHY 
============= */ 

// Body
// -----------------------------------------------------------------------------
body { 
    word-wrap: break-word; 
    /*rtl:raw:
    direction: rtl;
    unicode-bidi: embed;*/
}
 
// Link
// -----------------------------------------------------------------------------
a {
    transition: color .2s, background .2s, border .2s;

    &:hover,
    &:focus { 
        text-decoration : none; 
    }
}
h1, h2, h3, h4, h5, h6 { 
    a {
        color: inherit;
        
        &:hover,
        &:focus { 
            color: @primary 
        }                
    }
}

// Blockquote
// -----------------------------------------------------------------------------
blockquote {
        font-family     : @headings-font;
        font-size       : 20px;        
        padding         : 15px;
        
        &, p { line-height: 1.4 }
        
        footer { 
                margin-top: 10px;
        }
}

// Headings
// -----------------------------------------------------------------------------
h1, h2, h3, h4, h5, h6 {
        font-family     : @headings-font;
        font-weight     : bold; 
        margin          : 0 0 @font-size-base;
}

// Horizontal line
// -----------------------------------------------------------------------------
hr { 
        clear           : both;
        border-color    : @light-border;
        margin          : 0 0 15px;
}

// Paragraphs
// -----------------------------------------------------------------------------
p { 
        margin              : 0 0 15px;
}

// Columns
// -----------------------------------------------------------------------------
div.col-2,    
div.col-3,    
div.col-4 {
        -moz-column-gap     : 30px; /* Firefox */
        -webkit-column-gap  : 30px; /* Safari and Chrome */
        column-gap          : 30px;
}

div.col-2 { .column-count(2); }     
div.col-3 { .column-count(3); }     
div.col-4 { .column-count(4); } 

@media(max-width: 768px) { 
        div.col-3, 
        div.col-4 { .column-count(2); } 
} 
@media(max-width: 512px) { 
        div.col-2, 
        div.col-3,
        div.col-4 { .column-count(1); } 
} 

// Dropcaps
// -----------------------------------------------------------------------------
span.dropcaps,
.dropcaps:first-letter {
        float           : left;
        font-size       : 45.5px;
        line-height     : 40px;
        margin-right    : 5px; 
}

// Post meta
// -----------------------------------------------------------------------------
.post-meta,
.comment-meta,
.comment-container .reply { 
        a { 
                color           : @dark-text;  
                font-family     : @headings-font; 
                font-size       : 13px;
                line-height     : 1;  
                display         : inline-block;

                &:hover { color: @primary }
        }
}

.post-meta .dashicons {
        font-size       : 13px;
        height          : auto;
        width           : auto;
}

// Page header
// -----------------------------------------------------------------------------
.page-header {
        margin: 0 0 45px;
}