/* CONTENT  
========== */
#content {
        border-bottom       : 15px solid @light-border;
        min-height          : 480px; 
        padding-top         : 45px; 
        position            : relative;
        z-index             : 1;
}

body {
        > header,
        > #content,
        > footer {
                box-shadow      :  0 0 1px 0 rgba( 0, 0, 0, .4 ); 
        }
}