/* 404 PAGE 
=========== */
#page-404 #content {
        h1 { 
                font-size       : 100px; 
                margin-top      : 45px; 
        }
        
        p { 
                font-size       : 30px; 
        }
        
        &, p    { text-align    : center; }
        button  { margin-bottom : 100px; }
}